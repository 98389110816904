
.bot {
  h1 {
    font-size: 1.5rem;
    margin-bottom: 4px;
    border-bottom: gray solid 2px;
    span {
      margin-left: 5px;
      font-size: 1.0rem;
      font-weight: normal;
    }
  }
  .ballance {
    display: flex;
    ul li {
      float: left;
      font-size: 1.0rem;
      padding: 0;
      span {
        padding: 0.1rem;
        font-weight: bold;
      }
      margin: 5px;
    }

    ul {
      margin:0;
      padding: 0;
      justify-content: space-between;
    }
    border-radius: 2px;
    background-color:lightsalmon;

  }
}
